//
// Stylesheets
//
import '@stylesheets/application-pc.scss';

//
// bugsnag
//
import './utils/BugsnagInit';

//
// rails ujs
//
import './utils/RailsUjs';

//
// activestorage
//
import './utils/ActiveStorage';

//
// view_model
//

// common
import './common/view_models/AccordionToggle';
import './common/view_models/AnimeSeasonClip';
import './common/view_models/BlockLink';
import './common/view_models/Clip';
import './common/view_models/DramaSeasonClip';
import './common/view_models/MyMailAccepted';
import './common/view_models/TimedRedirect';
import './common/view_models/YoutubeTrailerThumbnail';

// pc
import './pc/view_models/UserCard';
import './pc/view_models/MarkCard';
import './pc/view_models/Profile';
import './pc/view_models/Feed';
import './pc/view_models/MarkLikeUser';
import './pc/view_models/FanLikeUser';
import './pc/view_models/ReviewDetailComment';
import './pc/view_models/DramaReviewDetailComment';
import './pc/view_models/AnimeReviewDetailComment';
import './pc/view_models/FanDetailComment';
import './pc/view_models/CountryList';
import './pc/view_models/CompanyList';
import './pc/view_models/AwardList';
import './pc/view_models/DecadeList';
import './pc/view_models/ReleaseYearList';
import './pc/view_models/Activity';
import './pc/view_models/ContentDetailPeopleCast';
import './pc/view_models/ContentDetailRelatedInfo';
import './pc/view_models/ContentDetailSynopsis';
import './pc/view_models/RawContentDetailSynopsis';
import './pc/view_models/MyMenu';
import './pc/view_models/MyProfileImage';
import './pc/view_models/SearchForm';
import './pc/view_models/FacebookRelation';
import './pc/view_models/TwitterRelation';
import './pc/view_models/AppleRelation';
import './pc/view_models/GoogleRelation';
import './pc/view_models/UserDetailFanPeople';
import './pc/view_models/ContentList';
import './pc/view_models/OnlinePreview';
import './pc/view_models/OnlinePreviewSample';
import './pc/view_models/Mark';
import './pc/view_models/TermAccept';
import './pc/view_models/Fan';
import './pc/view_models/Header';
import './pc/view_models/MovieSchedule';
import './pc/view_models/MovieScheduleStatic';
import './pc/view_models/MovieScheduleSwiper';
import './pc/view_models/LocalStorageManager';
import './pc/view_models/JackBanner';
import './pc/view_models/PrivacyPolicyAccept';
import './pc/view_models/RecommendSwiper';
import './pc/view_models/OtherLinksModal';
import './pc/view_models/AnchorLink';
import './pc/view_models/EpisodeInfo';
import './pc/view_models/TheaterCardSwiper';
import './pc/view_models/TheaterSchedule';
import './pc/view_models/MovieTheaterSchedule';
import './pc/view_models/TheatersHere';
import './pc/view_models/TheaterStatic';
import './pc/view_models/TheaterCard';
import './pc/view_models/NearestArea';
import './pc/view_models/HorizontalScroll';
import './pc/view_models/TrailerCollectionHorizontalScroll';
import './pc/view_models/Cassette';

import { onClickVisibleSpoiler } from './utils/Spoiler';

// ネタバレコメントの表示
document.addEventListener('DOMContentLoaded', (event) => {
  Array.prototype.forEach.call(document.querySelectorAll('.js-spoiler'), (elm) => {
    onClickVisibleSpoiler(elm);
  });
});

// Mark Shareボタン
window.addEventListener(
  'storage',
  (event) => {
    var active_class = 'is-active';
    var provider_reg = event.key.match(/^is_(twitter)_share$/);
    if (provider_reg) {
      var provider = provider_reg[1];
      var switch_class = '.c-switch--' + provider;
      var active = event.newValue === 'true';
      Array.prototype.forEach.call(document.querySelectorAll(switch_class), (btn) => {
        var active_now = btn.classList.contains(active_class);
        if (active == !active_now) {
          btn.click();
          if (active == true) {
            btn.classList.add(active_class);
          } else {
            btn.classList.remove(active_class);
          }
        }
      });
    }
  },
  false
);
